import { navigate } from "gatsby";
import { removeLocalStorageUserSession } from "./auth";

export const baseUrl = process.env.GATSBY_MARIA_BASE_URL;

export const mariaFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  enableRedirectionToLogin: boolean = true
): Promise<Response> => {
  if (!baseUrl) {
    console.error(`GATSBY_MARIA_BASE_URL is not set.`);
  }

  const url = `${baseUrl}${input}`;
  const response = await fetch(url, init);

  if (response.status === 401 && enableRedirectionToLogin) {
    removeLocalStorageUserSession();
    navigate("/login");
  }

  return response;
};
