import { UserType } from "./user";

export const instantLoanDashboardAccesible = (user: UserType) => {
  if (
    (user.nbLoans !== undefined && user.nbLoans > 0) ||
    (user.hasActiveLoan !== undefined && user.hasActiveLoan === true) ||
    (user.loanState !== undefined && user.loanState === 30)
  ) {
    return true;
  }
  return false;
};

export const referralProgramEligibleAccesible = (user: UserType) => {
  if (user.provinceId === 2 || user.provinceId === 6) {
    return false;
  }
  if (user.referralProgramEligible === true && user.isAmbassador === true) {
    return true;
  }
  return false;
};
