import styled from "styled-components"
import tw from "tailwind-styled-components";

const StyledDashboardLayoutContainer = styled.div`
  box-shadow: 0 5px 3.5px 0 rgba(0,0,0,0.23);
`;

export const DashboardLayoutContainer = tw(StyledDashboardLayoutContainer)`
  flex-1
  min-h-[calc(100vh-59px)]

  bg-interface-50
  rounded-[5px]
  mb-[15px]
  mr-[11px]
  w-full

  sm:shadow-none
  sm:rounded-none
  sm:mb-0
  sm:min-h-none
`;