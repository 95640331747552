import { mariaFetch } from "./maria-fetch";

export async function postSignIn(email: string, password: string) {
  return mariaFetch("/user/login", {
    method: "POST",
    headers: {
      "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  //   const data = await res.json();
  //   if (!!data.error) throw new Error(data.error.message);
  //   window.localStorage.iCashUserSessionID = data.result?.session?.token;
  // } catch (error) {
  //   alert(error);
  // }
}

export async function postForgotPassword(email: string) {
  return mariaFetch("/user/forgotpassword", {
    method: "POST",
    headers: {
      "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  //   const data = await res.json();
  //   if (!!data.error) throw new Error(data.error.message);
  // } catch (error) {
  //   console.log("error: ",error);
  // }
}

export function setLocalStorageUserSession(sessionId: string) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("iCashUserSessionID", sessionId);
  }
}

export function removeLocalStorageUserSession() {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("iCashUserSessionID");
  }
}

export function isLoggedIn() {
  let sessionId: string | null = null;

  if (typeof localStorage !== "undefined") {
    sessionId = localStorage.getItem("iCashUserSessionID");
  }

  if (sessionId) return true;

  removeLocalStorageUserSession();

  return false;
}

export function getUserSessionId() {
  let sessionId: string | null = null;

  if (typeof localStorage !== "undefined") {
    sessionId = localStorage.getItem("iCashUserSessionID");
    return sessionId;
  }

  return null;
}

export async function postResetPassword(password: string, confirmCode: string) {
  return mariaFetch("/user/forgotpasswordcompletion", {
    method: "POST",
    headers: {
      "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password, confirmCode }),
  });
}
