import * as React from "react";

import {
  Button,
  Modal,
  SelectInput,
  SidePanel,
} from "@brainfinance/icash-component-library";
import { Subheading } from "@components/typography";
import { SupportPanelProps } from "./support-panel";
import { getUserSessionId } from "@helpers/auth";
import { UserContext } from "@helpers/user";

import "./style.css";
import { mariaFetch } from "@helpers/maria-fetch";

type MessageReasonItemType = {
  value: number;
  label: string;
};

export const SupportPanel = (props: SupportPanelProps) => {
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [errorInputMessage, setErrorInputMessage] = React.useState<string>();
  const [errorSelectionTypeMessage, setErrorSelectionTypeMessage] =
    React.useState<string>();
  const [messageReason, setMessageReasons] = React.useState<
    MessageReasonItemType[]
  >([]);
  const [selectedReasonId, setSelectedReasonId] =
    React.useState<string | number | undefined>();
  const [value, setValue] = React.useState<string>("");

  const { userData } = React.useContext(UserContext);

  React.useEffect(() => {
    if (props.isOpen) {
      setErrorInputMessage(undefined);
      setErrorSelectionTypeMessage(undefined);
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    mariaFetch(
      "/message/contactus",
      {
        method: "GET",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
      },
      false
    )
      .then((res) => res.json())
      .then((data) => {
        setMessageReasons(
          data.result?.list.messageReasons.map((reason: any) => ({
            value: reason.id,
            label: reason.value,
          })) ?? []
        );
      });
  }, []);

  const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setErrorInputMessage(undefined);
  };

  const handleSubmit = () => {
    setErrorInputMessage(undefined);
    setErrorSelectionTypeMessage(undefined);
    if (!value.trim()) {
      setErrorInputMessage("Please enter your message.");
    }
    if (selectedReasonId === undefined) {
      setErrorSelectionTypeMessage("Please enter the topic.");
    }
    if (selectedReasonId !== undefined && value.trim()) {
      mariaFetch("/message/contactus", {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserSessionId()}`,
        },
        body: JSON.stringify({
          messageReasonId: selectedReasonId,
          email: userData.email,
          phoneNumber: userData.mobilePhone,
          message: value.trim(),
        }),
      }).then(() => {
        setValue("");
        props.handleClose();
        setShowSuccessModal(true);
      });
    }
  };

  return (
    <>
      <SidePanel
        active={props.isOpen}
        onOpen={props.handleOpen}
        onClose={props.handleClose}
      >
        <Subheading>Support</Subheading>
        <p className="global--paragraph mt-[44px]">
          iCASH is a technology-based company that focuses on offering faster
          and smarter loans. We offer our customer support exclusively by email
          and text. Our customer experience agents are responding to your emails
          7 days a week from 8 am to midnight eastern standard time.
        </p>
        <p className="global--paragraph mt-[33px] mb-[33px]">
          <b>Contact us</b>
          <br />
          <br />
          What is it about?
          <br />
          <SelectInput
            onChange={(value) => {
              setErrorSelectionTypeMessage(undefined);
              setSelectedReasonId(value);
            }}
            options={messageReason}
            className="w-1/2 sm:w-full max-w-full !mb-[30px] text-interface-300 font-500"
            error={errorSelectionTypeMessage}
          />
          Explain why you are contacting us
          <br />
          <br />
          <textarea
            className={`email-message-input global--input${
              value ? " global--with-value" : ""
            }`}
            onChange={handleValueChange}
            rows={6}
            value={value}
            placeholder="Type your message"
          />
          {errorInputMessage ? (
            <span className="global--input-error relative bottom-0">
              {errorInputMessage}
            </span>
          ) : null}
        </p>
        <div className="flex flex-col">
          <Button
            appearance="primary"
            className="self-end mb-[18px]"
            onClick={handleSubmit}
          >
            Send
          </Button>
        </div>
      </SidePanel>
      {showSuccessModal && (
        <Modal onClose={() => setShowSuccessModal(false)} className="min-h-fit">
          <div className="modal--content pt-[44px]">
            <p className="support-panel--success-title">
              Thank you for your request
            </p>
            <p className="support-panel--success-message mt-[8px]">
              Your message was successfully sent.
            </p>
            <div className="flex flex-1 justify-end mt-[20px]">
              <Button
                appearance="primary"
                size="small"
                onClick={() => setShowSuccessModal(false)}
                className="mr-[8px]"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
