import { createContext } from "react";

export type UserType = Partial<{
  ambassadorId: string;
  marketingAgreement: boolean;
  hasActiveLoan: boolean;
  isMuted: boolean;
  applicationStep: number;
  nbLoans: number;
  contactId: number;
  hasActiveApplication: boolean;
  shouldValidatePhone: boolean;
  manitobaNewApp: boolean;
  eligibleMultiTerms: boolean;
  provinceAvailable: boolean;
  applicationStatusId: number;
  lastName: string;
  mobilePhone: string;
  eligibleMultiTermsMessage: string;
  isCollection: boolean;
  email: string;
  isBlacklisted: boolean;
  firstName: string;
  isPendingBankingApplication: boolean;
  isConfirmedUser: boolean;
  isAmbassador: boolean;
  isReloan: boolean;
  provinceId: number;
  referralProgramEligible: boolean;
  contractExpirationDate: string;
  referralAmbassadorName: string;
  availableCashbackAmount: number;
  activeLoanId: number;
  loanState?: number;
}>;

export const UserContext = createContext<{
  userData: UserType;
  userLoading: boolean;
  userError?: string;
}>({
  userData: {},
  userLoading: true,
});
