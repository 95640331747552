import tw from "tailwind-styled-components"

export const DashboardTopBar = tw.div`
  h-[44px]
  pb-[5px]
  px-[22px]
  
  flex
  items-end
  justify-end
  
  sm:hidden
`