/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { DashboardProps } from "../types";
import { SEO } from "@components/page-layout";
import {
  DashboardLayoutBox,
  DashboardLayoutContainer,
  DashboardTopBar,
} from "../style";

import { Caption } from "@components/typography";
import { GatsbyLink } from "@components/gatsby-link";
import {
  DashboardDownloadApp,
  DashboardNavigation,
  DashboardNavigationItem,
} from "@brainfinance/icash-component-library";
import { UserContext } from "@helpers/user";
import { SupportPanel } from "@containers/support-panel";

// Images
import ios from "@images/iOS.svg";
import android from "@images/Android.svg";
import { removeLocalStorageUserSession } from "@helpers/auth";
import {
  instantLoanDashboardAccesible,
  referralProgramEligibleAccesible,
} from "@helpers/dashboardNavigationConditions";

export const DashboardLayout = (
  props: React.PropsWithChildren<DashboardProps>
) => {
  const location = useLocation();

  const { userData } = React.useContext(UserContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div
      className="bg-[#EAEAEA] min-h-[100vh]"
      onScroll={props.onContainerScroll}
    >
      <SEO title="Dashboard" bodyClass={`dashboard ${props.bodyClass || ""}`} />
      <DashboardTopBar>
        <Caption className="!text-interface-400 mr-[20px]">
          {props.userEmail}
        </Caption>
        <GatsbyLink className="leading-[20px]" to="/dashboard/loyalty-program">
          {props.userMembership}
        </GatsbyLink>
      </DashboardTopBar>
      <div className="flex sm:block">
        <DashboardNavigation
          logoPath={() => {
            navigate("/dashboard");
          }}
          menuTitle="Menu"
        >
          <DashboardNavigationItem
            onClick={() => {
              navigate("/dashboard");
            }}
            active={location.pathname === "/dashboard"}
          >
            Products & programs
          </DashboardNavigationItem>
          {instantLoanDashboardAccesible(userData) === true && (
            <DashboardNavigationItem
              onClick={() => {
                navigate("/dashboard/instant-loan");
              }}
              active={location.pathname.includes("/dashboard/instant-loan")}
            >
              Instant Loan
            </DashboardNavigationItem>
          )}
          {userData.provinceId === (1 || 10) ||
          (typeof userData.nbLoans !== "undefined" && userData.nbLoans < 10) ||
          // missing the income < $1500 / month
          userData.isCollection === true ||
          userData.isBlacklisted === true ? (
            <p className="mb-[22px]" />
          ) : (
            <DashboardNavigationItem onClick={() => {}} className="mb-[22px]">
              Personal Loan
            </DashboardNavigationItem>
          )}
          {referralProgramEligibleAccesible(userData) ? (
            <DashboardNavigationItem
              onClick={() => {
                navigate("/dashboard/referral-program");
              }}
              active={location.pathname === "/dashboard/referral-program"}
            >
              Referrals
            </DashboardNavigationItem>
          ) : null}
          <DashboardNavigationItem
            onClick={() => {
              navigate("/dashboard/loyalty-program");
            }}
            active={location.pathname === "/dashboard/loyalty-program"}
          >
            Loyalty Program
          </DashboardNavigationItem>

          {userData.provinceId !== 6 ? (
            <DashboardNavigationItem
              onClick={() => {
                navigate("/dashboard/wallet");
              }}
              active={location.pathname === "/dashboard/wallet"}
            >
              Wallet
            </DashboardNavigationItem>
          ) : null}
          <DashboardNavigationItem
            onClick={() => navigate("/dashboard/profile")}
            className="mt-[22px]"
            active={location.pathname === "/dashboard/profile"}
          >
            Profile
          </DashboardNavigationItem>
          <DashboardNavigationItem onClick={() => setIsOpen(true)}>
            Support
          </DashboardNavigationItem>
          <DashboardNavigationItem onClick={() => {}} className="mb-[22px]">
            <a href="/faq" target="_blank" className="block">
              FAQ
            </a>
          </DashboardNavigationItem>

          <DashboardNavigationItem
            className="mb-[22px]"
            onClick={() => {
              removeLocalStorageUserSession();
              navigate("/login");
            }}
          >
            Log out
          </DashboardNavigationItem>
          <DashboardDownloadApp
            iosLink="https://icash-ca.app.link/URLVQwAR9X"
            androidLink="https://icash-ca.app.link/7MQFYetR9X"
            iosImg={ios}
            androidImg={android}
            className="!px-0"
          >
            Download the iCash mobile app and get back on track to financial
            health anywhere, at any time.
          </DashboardDownloadApp>
        </DashboardNavigation>
        <DashboardLayoutContainer className={props.containerClass ?? ""}>
          {props.children}
        </DashboardLayoutContainer>
      </div>
      <SupportPanel
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </div>
  );
};
