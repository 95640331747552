import * as React from "react";
import { UserContext } from "@helpers/user";

import { CardBox, Modal } from "@brainfinance/icash-component-library";
import { GatsbyLink } from "@components/gatsby-link";
import { navigate } from "gatsby";
import { mariaFetch } from "@helpers/maria-fetch";
import { DashboardLayout } from "@components/dashboard-layout";
import { useLocation } from "@reach/router";

function getMembership(nbLoans?: number) {
  if (typeof nbLoans === "number") {
    if (nbLoans >= 20) return "VIP";
    if (nbLoans >= 10) return "Prestige";
  }
  return "Elite";
}

type ChangeEmailConfirmPropsType = {
  path: string;
  id?: string;
  code?: string;
  params: Record<string, any>;
};

const ChangeEmailConfirm = (props: ChangeEmailConfirmPropsType) => {
  const { userData } = React.useContext(UserContext);
  const [showModal, setShowModal] = React.useState(false);
  const [email, setEmail] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string>();
  const location = useLocation();

  React.useEffect(() => {
    const search = location.search;
    const code = new URLSearchParams(search).get("code");

    if (code) {
      onConfirmEmail(code);
    }
  }, [location]);

  const onConfirmEmail = async (code: string) => {
    try {
      const res = await mariaFetch("/user/changeemailconfirm", {
        method: "POST",
        headers: {
          "Application-ID":
            "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ confirmCode: code }),
      });
      const data = await res.json();
      if (data && data.error) {
        setError(data.error?.message ?? "");
        setShowModal(true);
      }
      if (res.status === 200) {
        const loginEmail = data.result?.loginEmail as string | undefined;
        if (loginEmail) {
          setEmail(loginEmail);
          setShowModal(true);
        }
      } else {
        setShowModal(true);
      }
    } catch (error) {
      setShowModal(true);
    }
  };

  const onEditEmail = () => {
    navigate("/dashboard/profile/change-email");
  };

  return (
    <>
      <DashboardLayout userEmail="" userMembership="">
        <div className="w-full p-[44px] md:p-[22px] sm:p-[11px]">
          <div className="w-full max-w-[1138px] p-[44px] md:p-[22px] sm:p-[11px]">
            <div className="sm:px-[11px]">
              <h1 className="global--subheading mb-[11px] text-[#707070] sm:text-[37px] sm:leading-[50px]">
                Profile
              </h1>
            </div>
            <div className="flex-[2_0_48%] last:max-w-[55%] mb-0">
              <CardBox>
                <div className="flex flex-1 flex-col">
                  <h2 className="global--small-title text-[26px] text-[#707070] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
                    Personal information
                  </h2>
                  <div className="flex w-full">
                    <div className="w-1/2">
                      <p className="global--label text-[#707070] mb-[10px]">
                        Name
                      </p>
                      <p className="text-[15px] text-[#707070] font-700 mb-[22px]">
                        {userData.firstName} {userData.lastName}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="global--label text-[#707070] mb-[10px]">
                        Email
                      </p>
                      <p className="text-[15px] text-[#707070] font-700">
                        {userData.email}
                        <span
                          onClick={onEditEmail}
                          className="ml-[16px] text-[15px] font-400 underline underline-offset-1 cursor-pointer"
                        >
                          Edit
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="global--label text-[#707070] mb-[10px]">
                      Learn how we manage your privacy{" "}
                      <GatsbyLink
                        className="underline underline-offset-1 leading-[20px]"
                        to="/"
                      >
                        here
                      </GatsbyLink>
                      .
                    </p>
                  </div>
                </div>
              </CardBox>
              <CardBox>
                <div className="flex flex-1 flex-col">
                  <h2 className="global--small-title text-[#707070] text-[26px] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
                    Membership
                  </h2>
                  <div className="flex w-full">
                    <div className="w-1/2">
                      <p className="global--label text-[#707070] mb-[10px]">
                        Status
                      </p>
                      <p className="text-[20px] text-[#707070] font-600 mb-[20px]">
                        {getMembership(userData.nbLoans)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="global--label text-[#707070] mb-[10px]">
                      Learn how more about your benefits{" "}
                      <GatsbyLink
                        className="underline underline-offset-1 leading-[20px]"
                        to="/"
                      >
                        here
                      </GatsbyLink>
                      .
                    </p>
                  </div>
                </div>
              </CardBox>
            </div>
            {showModal && (
              <Modal
                onClose={() => {
                  if (error) {
                    navigate("/login");
                  } else {
                    navigate("/dashboard/profile");
                  }
                  setShowModal(false);
                }}
                className="min-h-min"
              >
                {error ? (
                  <div className="modal--content mt-[44px]">
                    <h2 className="global--small-title text-[26px] text-[#707070] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
                      Something went wrong
                    </h2>
                    <p className="global--label text-[#707070] mb-[10px] whitespace-pre-line">
                      {error}
                    </p>
                  </div>
                ) : (
                  <div className="modal--content mt-[44px]">
                    <h2 className="global--small-title text-[26px] text-[#707070] sm:text-[17px] font-600 leading-[36px] mb-[22px] sm:mb-[11px] sm:leading-[18px]">
                      Email successfully changed
                    </h2>
                    <p className="global--label text-[#707070] mb-[10px] whitespace-pre-line">
                      Your email has been verified and updated to{" "}
                      <span>{email}</span>.{"\n"}Your upcoming e-transfers will
                      be sent to this new email address.
                    </p>
                  </div>
                )}
              </Modal>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default ChangeEmailConfirm;
